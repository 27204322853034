#footer{
    width:100%;
    float:left;
    overflow:hidden;
    margin-bottom: 3rem;
    @media screen and (max-width:767px) {
        margin-bottom: 3rem;
    }

    & *{
        font-size: 1.2rem;

        @media screen and (max-width:1366px) {
            font-size: 1.2rem;
        }
    }

    #footer-fixo{
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
       
        @media screen and (min-width: 768px) {
            z-index: 900;
        }

        @media screen and (max-width:767px) {
            position: relative;
            left: unset;
            bottom: unset;
            right: unset;
        }

        .col-gray{
            background-color:#383535;
            font-size: 18px; 
            font-weight: bold;
        }
        .col-green{
            background-color:#42A723;
            font-size: 18px;
            font-weight: bold;
        }
        .col-blue{
            background-color:#42B8B3;

            .buy-link{
                outline: none;
                font-size: 1.2rem;
                color: white;
                background-color:#42B8B3;
                display: block;
                text-decoration: none;
                transition: all 0.4s ease;
                border-radius: 10px 25px 25px 10px;
                position: relative;

                .arrow-fancy{
                    position: absolute;
                    top: 5px;
                    right: -60px;
                    height: 34px;
                    width: 34px;
                    background-color: #5BDED9;
                    border-radius: 100%;
                    
                    i{
                        margin-right: -3px;
                    }
                }

            }
        }
    }

    .bg1{
        background: rgb(231,231,231);
        background: -moz-linear-gradient(180deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 29%);
        background: -webkit-linear-gradient(180deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 29%);
        background: linear-gradient(180deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 29%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e7e7e7",endColorstr="#ffffff",GradientType=1);
        width:100%;
        float:left;
        overflow:hidden;
        padding-top:30px;
        padding-bottom:30px;
        
        @media screen and (max-width:767px) {
            padding-top:25px;
            padding-bottom:15px;
        }

        #logo-footer{
            @media screen and (max-width:767px) {
                display: none;
            }
        }
        
        p{
            width:100%;
            float:left;
            overflow:hidden;
            text-align:center;
            color:$cor1;
            padding-top:20px;

            @media screen and (max-width:767px) {
                padding-top: 0;
                margin-bottom: 0;
            }
        }
        
        ul{
            float:right;
            padding-left:0px;

            @media screen and (max-width:767px) {
                float:left;
                padding-left:10px;
            }

            li{
                display:inline;
                float:left;
                list-style:none;
                padding-left: 1em; 
                a{
                    float:left;
                    color:$cor1;
                    padding-top:20px;
                    padding-right:10px;
                    margin-right:10px;

                    &:last-child{
                        padding-right:0px;
                        margin-right:0px;    
                    }
                }
            }
        }
    }

    .bg2{
        background: rgb(213,213,213);
        background: -moz-linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 100%);
        background: linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d5d5d5",endColorstr="#ffffff",GradientType=1);
        width:100%;
        float:left;
        overflow:hidden;
        padding-top:30px;
        padding-bottom:30px;
        
        @media screen and (max-width:767px) {
            padding-top:25px;
            padding-bottom:85px;
        }

        span{
            width:100%;
            float:left;
            overflow:hidden;
            text-align:center;
            font-size:16px;
            color:$cor1;
        }

        img{
            display:block;
            margin:10px auto 0px;
        }
    }
}