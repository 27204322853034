@font-face{
    font-family:'effralight';
    src:url('../fonts/dalton_maag_-_effra_light-webfont.eot');
    src:url('../fonts/dalton_maag_-_effra_light-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/dalton_maag_-_effra_light-webfont.woff2') format('woff2'),
        url('../fonts/dalton_maag_-_effra_light-webfont.woff') format('woff'),
        url('../fonts/dalton_maag_-_effra_light-webfont.ttf') format('truetype'),
        url('../fonts/dalton_maag_-_effra_light-webfont.svg#effralight') format('svg');
        font-weight:normal;
        font-style:normal
}

@font-face{
    font-family:'effrabold';
    src:url('../fonts/effra_std_bd-webfont.eot');
    src:url('../fonts/effra_std_bd-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/effra_std_bd-webfont.woff2') format('woff2'),
        url('../fonts/effra_std_bd-webfont.woff') format('woff'),
        url('../fonts/effra_std_bd-webfont.ttf') format('truetype'),
        url('../fonts/effra_std_bd-webfont.svg#effrabold') format('svg');
        font-weight:normal;
        font-style:normal;
}

@font-face{
    font-family:'effra_heavyregular';
    src:url('../fonts/effra_std_he-webfont.eot');
    src:url('../fonts/effra_std_he-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/effra_std_he-webfont.woff2') format('woff2'),
        url('../fonts/effra_std_he-webfont.woff') format('woff'),
        url('../fonts/effra_std_he-webfont.ttf') format('truetype'),
        url('../fonts/effra_std_he-webfont.svg#effra_heavyregular') format('svg');
        font-weight:normal;
        font-style:normal
}

@font-face{
    font-family:'effra_mediumregular';
    src:url('../fonts/effra_std_md-webfont.eot');
    src:url('../fonts/effra_std_md-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/effra_std_md-webfont.woff2') format('woff2'),
        url('../fonts/effra_std_md-webfont.woff') format('woff'),
        url('../fonts/effra_std_md-webfont.ttf') format('truetype'),
        url('../fonts/effra_std_md-webfont.svg#effra_mediumregular') format('svg');
        font-weight:normal;
        font-style:normal
}

@font-face{
    font-family:'effraregular';
    src:url('../fonts/effra_std_rg-webfont.eot');
    src:url('../fonts/effra_std_rg-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/effra_std_rg-webfont.woff2') format('woff2'),
        url('../fonts/effra_std_rg-webfont.woff') format('woff'),
        url('../fonts/effra_std_rg-webfont.ttf') format('truetype'),
        url('../fonts/effra_std_rg-webfont.svg#effraregular') format('svg');
    font-weight:normal;
    font-style:normal;
}
