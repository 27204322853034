@mixin gradient-vertical ($firstColor: #FFF, $secondColor: #DDD) {
    background  : $firstColor; /* Fallback solid color for old browsers */
    background  : linear-gradient(to bottom, $firstColor 0%, $secondColor 100%); /* W3C */
    background  : -moz-linear-gradient(top, $firstColor 0%, $secondColor 100%); /* FF 3.6+ */
    background  : -ms-linear-gradient(top, $firstColor 0%, $secondColor 100%); /* IE 10+ */
    background  : -o-linear-gradient(top, $firstColor 0%, $secondColor 100%); /* Opera 11.10+ */
    background  : -webkit-linear-gradient(top, $firstColor 0%,$secondColor 100%); /* Chrome 10+, Safari 5.1+ */
    background  : -webkit-gradient(linear, left top, left bottom, color-stop(0%,$firstColor), color-stop(100%,$secondColor)); /* Chrome, Safari 4+ */
    filter      : progid:DXImageTransform.Microsoft.gradient(startColorstr= '#{$firstColor}', endColorstr= '#{$secondColor}', GradientType=0); /* IE 6-9 */
}

@mixin gradient-horizontal ($firstColor: #FFF, $secondColor: #DDD) {
    background  : $firstColor; /* Fallback solid color for old browsers */
    background  : linear-gradient(to right, $firstColor 0%, $secondColor 100%); /* W3C */
    background  : -moz-linear-gradient(left,  $firstColor 0%, $secondColor 100%); /* FF 3.6+ */
    background  : -ms-linear-gradient(left, $firstColor 0%, $secondColor 100%); /* IE 10+ */
    background  : -o-linear-gradient(left, $firstColor 0%, $secondColor 100%); /* Opera 11.10+ */
    background  : -webkit-linear-gradient(left,  $firstColor 0%,$secondColor 100%); /* Chrome 10+, Safari 5.1+ */
    background  : -webkit-gradient(linear, left top, right top, color-stop(0%,$firstColor), color-stop(100%,$secondColor)); /* Chrome, Safari 4+ */
    filter      : progid:DXImageTransform.Microsoft.gradient(startColorstr= '#{$firstColor}', endColorstr= '#{$secondColor}', GradientType=1); /* IE 6-9 */
}

.centraliza{
    width:fit-content;
    display:block;
    margin:0px auto;
}