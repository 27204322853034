@import "../variables";
@import "../mixins";
@import "../sprites";
@import "../fonts";

body{
    font-family: 'effraregular';    
    background: #d6d6d6;
}

.modal-backdrop{
	@include gradient-vertical($cor1, $cor2);
	opacity: .8;
}

.btn-primary {
    background-color: $cor1;
	border-color: $cor1;
	
	&:hover{
		background-color: $cor2;
		border-color: $cor2;	
	}
}


#box-preloader{
	background  : rgba($cor1,0.85);
	background:linear-gradient(to right, rgba($cor1,0.85) 0%, rgba($cor2,0.85) 100%);
	width:100%;
	height:100%;
	position:fixed;
	z-index: 999;
	display:none;

	div{
		width:130px;
		display:block;
		margin:0px auto;
		overflow:hidden;
		padding-top:18.5%;

		img{
			display:block;
			margin:0px auto;
		}

		span{
			width:100%;
			display:block;
			padding-top:10px;
			text-align:center;
			color:#fff;
			font-size:20px;

		}
	}
}

#site{
    background: #fff;
    width:100%;
    max-width:2000px;
    display:block;
    margin:0px auto;
    overflow:hidden;
    position:relative;
    transition:all 0.4s;
}


@import 'header';
@import 'footer';


#label-restam{
	color: #fff;
	text-align: center !important;
	width: 100%;
	float: left;
	padding-top: 19px;
	font-size: 23px;
	padding-bottom: 12px;
	display: initial !important;
}

@media screen and (max-width: 767px){
	.flip-clock-wrapper {
		margin-top: 1em !important;
		width: fit-content !important;
		margin: 0px auto;
		display: block;
	}
}