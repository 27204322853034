$cor1: #0E152E;
$cor6: #1c00bdc0;
$cor7: #7665d6dc;


#header{
    width:100%;
    float:left;
    position:fixed;
    top:0px;
    left:0px;
    z-index:99;

    @media screen and (max-width:767px) {
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25),0px 0px 15px 0px rgba(0,0,0,0.25);
    }

    &.exibe-cidades{
        .bloco-cidades-header{           
            height:150px;
            padding-top:25px;
            padding-bottom:25px;
            overflow:hidden;
            box-shadow: 0 0 10px rgba(0,0,0,0.25);
            @media(max-width:767px){
                height:auto;
               
            }
        }

        .bg1{
            z-index:1;
        }

        .bg2{
            
            #box-cor2{
                a{
                    img{
                        -webkit-transform:rotate(180deg);
                            -ms-transform:rotate(180deg);
                                transform:rotate(180deg);
                    }
                }
            }
           
        }
    }

    &.fixed{
        #box-menu-usuario{
            ul{
                @media screen and (max-width:767px) {
                    margin-top: -167px !important;
                }
            }
        }
        .bg1{
            padding-top:8px;
            padding-bottom:4px;

            @media screen and (max-width:767px) {
                padding-top: 15px;
                padding-bottom: 6px;
            }

            #logo-header{
                width: 160px;
                @media screen and (max-width:767px) {
                    width: 100px;
                }
            }

            .esquerda-header{  
                padding: 2px 0 0 0 !important;
                
                @media screen and (max-width:767px) {
                    padding: 8px 0 0 0 !important;
                }
              
            }
            .direita-header{
                padding: 2px 2px 0px 2px;

                @media screen and (max-width:767px) {
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
                    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
                }

                .lista-menu-acesso{
              
                    @media(max-width:767px){
                        margin-top: -126px !important;
                        
                    }
                }
            }
        }   
     
        
    }

    .bg1{
        // background-color:#fff;
        background: transparent;
        width:100%;
        float:left;
        padding-top:15px;
        padding-bottom:6px;
        position:relative;
        z-index:5;
        -webkit-transition:all 0.4s;
        -o-transition:all 0.4s;
        transition:all 0.4s;
        -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.15);

        @media screen and (max-width:767px) {
            width:45%;
            min-height: 60.02px;
        }

        @media screen and (max-width:321px) {
            width:45%;
            min-height: 60.02px;
        }

        .bg-white{
            @media screen and (max-width:767px) {
                min-height: 59.5px;
            }
        }

        .menu-mobile{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            background: white;
            z-index: 999;
            box-shadow: 0 0 10px rgba(0,0,0,0.15);

            .conteudo-menu-mobile{
                position: fixed;
                left: 0;
                right: 0;
                bottom: -1000px;
                top: 100%;
                z-index: 998;
                opacity: 0;
                transition: all 0.4s ease;
                background: transparentize($color: $cor1, $amount: 0.05);

                .col-menu{
                    margin-top: 250px;
                    opacity: 0;
                    transition: all 1s ease;

                    &.bg-white{
                        a{
                            color: $cor3;
                        }
                    }
                }

                a{
                    color: white;
                    font-weight: bold;
                    font-size: 18px;

                    &#btn-buscar-topo-mobile{
                        border-color: white;
                    }

                    &.menu-item{
                        // border-bottom: 2px solid $cor6;
                    }
                }

                &.aberto{
                    bottom: 41px;
                    top: 0;
                    opacity: 1;
                    .col-menu{
                        margin-top: 0;
                        opacity: 1;
                    }
                }
            }

            .btn-area-aluno{
                background: $cor1;
                border-radius: 50px;
                font-size: 14px;
                height: unset;
                padding: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .btn-zap{
                background-color: #2fb644;
                border-radius: 100%;
                padding: 5px;
                padding-top: 2px;
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 18px;
                width: 30px;
                height: 30px;
                text-align: center;
            }
            .btn-menu-mobile{
                color: $cor3;
                font-size: 20px;
            }
        }
        .centraliza-logo-mobile{
            @media screen and (max-width:767px) {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding-right: 3em;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;   
            }         
        }
        
        #logo-header{
            float:left;
            -webkit-transition:all 0.4s;
            -o-transition:all 0.4s;
            transition:all 0.4s;
            width:230px;

            @media(max-width:767px){
                width: 100px;
            }
        }

        .esquerda-header{
            float: right;
            padding-top: 12px;
            -webkit-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;

            @media screen and (max-width:992px) {
                display: none;
            }
            @media screen and (max-width:767px) {
                &.exibir-mobile{
                    display: initial !important;
                    position: absolute;
                    bottom: -50px;
                    left: 0;
                    right: -30%;
                }
  
                  .img{
                    width: 30px;
                    height: 30px;
                    border: 2px solid $cor3;
                    background: white;
                    line-height: 26px;
                    color: $cor3;
                    text-align: center;
                    border-radius: 100%;
                }

                .botao{
                    height: 35px !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                    span{
                        padding: 8px 0px 0px 0px !important;
                        font-size: 14px !important;
                    }
                    img{
                        margin: 8px 0px 0px 11px !important;
                        width: 20px !important;
                    }
                }
            }
            &.exibir-mobile{
                display: none;
                
            }
           
            .botao{
                background-color:#258D35;
                width:-webkit-fit-content;
                width:-moz-fit-content;
                width:fit-content;
                height:46px;
                display:block;
                padding-left:30px;
                padding-right:30px;
                margin:0px auto;
                overflow:hidden;
                border-radius:40px;
                -webkit-transition:all 0.4s;
                -o-transition:all 0.4s;
                transition:all 0.4s;
    
                @media(max-width:767px){
                    height: 65px;
                    
                }
    
                &:hover{
                    background-color:#2fb644;
    
                    img{
                        -webkit-transform:rotate(90deg);
                            -ms-transform:rotate(90deg);
                                transform:rotate(90deg);
                    }
                }
    
                span{   
                    float:left;
                    padding: 10px 0px 0px 0px;
                    overflow: hidden;
                    font-size: 18px;
                    color:#fff;
                    font-weight:bold;
    
                    @media(max-width:767px){
                        padding: 15px 0px 0px 0px;
                        font-size: 23px;
                    }
                }
    
                img{
                    float: left;
                    margin: 8px 0px 0px 17px;
                    -webkit-transition:all 0.4s;
                    -o-transition:all 0.4s;
                    transition:all 0.4s;
                    width: 30px;
                    @media(max-width:767px){
                        margin: 15px 0px 0px 13px;
                        width: 30px;
                    }
                }
            }
        }    

        .direita-header{
            float:right;
            padding:12px;
            -webkit-transition:all 0.4s;
            -o-transition:all 0.4s;
            transition:all 0.4s;

            @media(max-width:767px){
                position:fixed;
                bottom:0px;
                left:0px;
                background-color:#fff;
                width:100%;

                display: -webkit-box;

                display: -ms-flexbox;

                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
            }

            #link-area-aluno{
                background-color:$cor1;
                width:192px;
                height:46px;
                float:left;
                color:#fff;
                font-weight:bold;
                font-size:18px;
                text-transform:uppercase;
                border-radius:23px;
                text-align:center;
                padding-top:11px;

                @media(max-width:767px){
                    width: 105px;
                    height: 38px;
                    font-size: 12px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    padding: 0;
                }

                @media(max-width:321px) {
                    font-size: 10px;
                }

                &:hover{
                    background-color:$cor2;
                    text-decoration:none;
                }
            }
            
            
            #redes-sociais{
                float:left;
                padding-left:0px;
                margin-left:30px;
                margin-top:8px;

                @media(max-width:767px){
                    margin: 0 auto;
                }

                li{
                    display:inline;
                    list-style:none;
                    float:left;
                    margin-left:5px;

                    @media screen and (max-width:321px) {
                        margin-left:1px;     
                        
                        width: 27px;
                        height: 27px;
                    }

                    a{
                        &:hover{
                            -webkit-transform:scale(1.1);
                                -ms-transform:scale(1.1);
                                    transform:scale(1.1);
                        }

                        float:left;
                        -webkit-transition:all 0.4s;
                        -o-transition:all 0.4s;
                        transition:all 0.4s;
                    }
                }
            }
            
            #box-menu-usuario{
                float:left;
                height:40px;
                padding-left:0px;
                margin-left:35px;

                @media(max-width:767px){
                    float:right;
                    margin-left: 0;
                    height:unset;
                }

                &:hover{
                    .img{
                        @media(min-width:767px){
                            background-color:#eee;
                            -webkit-transform:scale(2);
                                -ms-transform:scale(2);
                                    transform:scale(2);
                            border-radius:8%;
                            margin-top:5px;
                        }
                    }

                    ul{
                        
                        @media screen and (min-width:768px) {
                            display:block;    
                        }   
                        
                        @media(min-width:767px){
                            border-bottom-left-radius:5px;
                            border-bottom-right-radius:5px;
                        }

                        &.form-ativo-mobile{
                            display:block;
                        }
                    }
                }

                .img{
                    background-size:cover;
                    background-color:#fff;
                    width:46px;
                    height:46px;
                    float:left;
                    overflow:hidden;
                    border-radius:50%;
                    border:3px solid $cor1;
                    -webkit-transition:all 0.4s;
                    -o-transition:all 0.4s;
                    transition:all 0.4s;
                    font-size: 26px;
                    font-weight: bold;
                    text-align: center;
                    color:$cor1;
                }
                
                ul{
                    background-color:#fff;
                    width:200px;
                    float:left;
                    overflow:hidden;
                    padding-left:0px;
                    border:1px solid $cor1;
                    position:absolute;
                    margin-top: 72px;
                    margin-left: -67px;
                    display:none;
                    z-index:99;
                    -webkit-transition:all 0.4s;
                    -o-transition:all 0.4s;
                    transition:all 0.4s;

                    @media(max-width:767px){
                        margin-top: -177px;
                        margin-left: -64px;
                    }

                    li{
                        width:100%;
                        float:left;
                        border-bottom:1px solid $cor1;

                        &:last-child{
                            border:0px;
                        }

                        a{
                            background-color:#fff;
                            width:100%;
                            height:40px;
                            line-height:40px;
                            float:left;
                            -webkit-transition:all 0.4s;
                            -o-transition:all 0.4s;
                            transition:all 0.4s;
                            color:$cor1;
                            padding-left:15px;

                            &:hover{
                                background-color:$cor2;
                                color:#fff;
                                text-decoration:none;
                            }
                        }
                    }
                }
            }
            
            #box-menu-principal{
                float:left;
                padding-left:0px;
                margin-left:30px;

                @media(max-width:767px){
                    float:right;
                    margin-left:15px;
                }

                &:hover{
                    .menu{
                        @media screen and (min-width:768px) {
                            background-color:$cor2;
                        }   
                    }

                    > ul{
                        @media screen and (min-width:768px) {
                            display:block;    
                        }        

                        &.form-ativo-mobile{
                            display:block;
                        }
                    }
                }

                .menu{
                    float:left;
                    padding: 10px 8px;
                    overflow:hidden;
                    color:$cor1;
                }

                ul{
                    background-color:#fff;
                    width:200px;
                    float:left;
                    overflow:hidden;
                    padding-left:0px;
                    border:1px solid $cor1;
                    position:absolute;
                    margin-top: 46px;
                    margin-left: -153px;
                    display:none;
                    z-index:99;
                    list-style-type: none;

                    @media(max-width:767px){
                        -webkit-transition: all 0.4s ease;
                        -o-transition: all 0.4s ease;
                        transition: all 0.4s ease;
                        margin-top: -136px;
                        margin-left: -151px;
                    }

                    li{
                        width:100%;
                        float:left;
                        border-bottom:1px solid $cor1;
                        list-style-type: none;

                        &:last-child{
                            border:0px;
                        }

                        &.destaque{
                            a{
                                background-color:#ddd;
                            }
                        }

                        a{
                            background-color:#fff;
                            width:100%;
                            height:40px;
                            line-height:40px;
                            float:left;
                            -webkit-transition:all 0.4s;
                            -o-transition:all 0.4s;
                            transition:all 0.4s;
                            color:$cor1;
                            padding-left:15px;
                            font-size:14px;
                            font-weight:bold;
                            text-transform:uppercase;

                            &:hover{
                                background-color:$cor2;
                                color:#fff;
                                text-decoration:none;
                            }
                        }

                        ul{
                            display:none;
                        }
                    }
                }
            }
        }
    }

    .bg2{
        // background: rgba($cor2,1);
        // background: -webkit-gradient(linear, left top, right top, from(rgba($cor2,1)), color-stop(50%, rgba($cor2,1)), color-stop(50%, rgba($cor1,1)), to(rgba($cor1,1)));
        // background: -o-linear-gradient(left, rgba($cor2,1) 0%, rgba($cor2,1) 50%, rgba($cor1,1) 50%, rgba($cor1,1) 100%);
        // background: linear-gradient(90deg, rgba($cor2,1) 0%, rgba($cor2,1) 50%, rgba($cor1,1) 50%, rgba($cor1,1) 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#284143",endColorstr="#0074a7",GradientType=1);
        width:100%;
        float:left;
        position:relative;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        z-index:-1;       
        
        @media screen and (max-width:767px) {
            width:55%;
            height: 60.02px;           
        }
        @media screen and (max-width:321px) {
            width:55%;
            height: 60.02px;           
        }

        &.logado{
            .container{
                background-color:rgba($cor1,1);
            }
        }

        &.ocultar-mobile{
            @media screen and (max-width:767px) {
                margin-top: -70px;
            
            }
        }


        .row-clip{
            @media screen and (min-width:1366px) {
                position: relative;
                border-left: 1px solid $cor1;
                clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 100%);
                // clip-path: polygon(1.5% 0, 100% 0, 98.5% 100%, 0% 100%);
                // &:after{
                //     content: ' ';
                //     position: absolute;
                //     top: 0;
                //     bottom: 0;
                //     left: 100%;
                //     right: -20px; 
                //     clip-path: polygon(0 0, 0 100%, 100% 0);
                //     // clip-path: polygon(100% 40%, 87% 79%, 0 79%, 13% 40%);
                //     background-color: $cor7;
                // }
                &:before{
                    content: ' ';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -20px;
                    right: 100%; 
                    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0% 100%);
                    // clip-path: polygon(0 0, 0 100%, 100% 0);

                    // clip-path: polygon(100% 40%, 87% 79%, 0 79%, 13% 40%);
                    background-color: $cor1;
                
                }
            }    
        }

        #box-cor1{
            background-color:$cor1;
            // float:left;
            padding:17px 20px 15px 20px;

            @media(max-width:767px){
                width:calc(100% - 45px);
                padding: 10px;
                height: 60.02px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
            }
            
            span{
                float:left;
                font-size:14px;
                color:#fff;
                text-transform:uppercase;

                @media(max-width:767px){
                    font-size:13px;
                }

                @media(max-width:350px){
                    display:none;
                }


                &.texto-oculto-mobile{
                    @media screen and (max-width:767px) {
                        display: none;
                    }
                }
            }

            strong{
                float:left;
                padding-left:10px;
                color:$cor5;
                text-transform:uppercase;
                font-size:14px;

                @media(max-width:767px){
                    font-size:13px;
                    padding-top: 6px;
                    line-height: 1.1em;
                }
            }
        }

        #box-cor2{
            background-color:rgba($cor1,1);
            min-width:235px;
            // float:left;
            padding:15px 15px 0px 15px;

            @media(max-width:767px){
                min-width:45px;
                width:45px;
                height: 60.02px;
            }

            a{
                float:left;

                span{
                    color:#fff;
                    float:left;

                    @media(max-width:767px){
                        display:none;
                    }
                }

                img{
                    float:left;
                    margin:7px 0px 0px 10px;
                    -webkit-transition:all 0.4s;
                    -o-transition:all 0.4s;
                    transition:all 0.4s;

                    
                    @media(max-width:767px){
                        margin-left:0px;
                    }
                }
            }
        }
        .block-col{
            font-weight: bold; 
            left: 1px;

            &.block-col-1{
                background: $cor6;
    left: 17px;

                @media screen and (min-width:1366px) {
                    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0% 100%); 
                }
            }
            &.block-col-2{
                background: $cor7;
                position: relative;
                clip-path: polygon(9% 0%, 100% 0%, 91% 100%, 0% 100%);
                .img{
                    width: 35px;
                    height: 35px;
                    border: 2px solid white;
                    background: $cor7;
                    line-height: 30px;
                    color: white;
                    text-align: center;
                    border-radius: 100%;
                }
            
                ul{
                    position: absolute;
                    top: 100%;
                    left: -15px;
                    right: 0;
                    bottom: 0;
                    overflow: hidden;
                    color: white;
                    padding-left: 0;

                    li{
                        background: $cor1;
                        list-style-type: none;
                        padding-left: 20px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        transition: all 0.4s ease;

                        &:hover{
                            background: lighten($color: $cor1, $amount: 10);
                        }
                        a{
                            display: block;
                            text-decoration: none;
                            color: white;
                        }
                    }
                }
                &:hover{
                    ul{
                        
                        overflow: visible;
                    }
                }
            }
        }

        #link-logout{
            background-color:#0074a7;
            width:100%;
            height:53px;
            float:right;
            font-size:16px;
            color:#fff;
            font-weight:bold;
            padding:14px 20px;
        }
    }

    .bloco-cidades-header{
        background-color:#fff;
        width:100%;
        height:0px;
        overflow:hidden;
        float:left;
        position:relative;
        -webkit-transition:all 0.4s;
        -o-transition:all 0.4s;
        transition:all 0.4s;
        z-index:-1;

        .logo-oculta{
            display: none;
        }
        &.cidade-is-null{
            position: fixed;
            width: 100%;
            border-radius: 0px;
            top: 0%;
            left: 0%;
            right:0%;
            bottom: 0%;
            z-index: 999;
            padding: 2.5em;
            height: auto;
            background-color: $cor2;
            background-size:cover;
            background-position: right;
            
            @media screen and (max-width:1366px) {
                background-position: center; 
            }
            .logo-oculta{
                display: initial;
                margin: 0 auto;
                margin-bottom: 1em;
                @media screen and (max-width:767px) {
                    -webkit-transform: scale(0.8);
                        -ms-transform: scale(0.8);
                            transform: scale(0.8);   
                }
            }
            .container{
                max-width: 25%;
                position: fixed;
                right: 0;
                top: 0;
                bottom: 0;
                background: white;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                padding-bottom: 15%;
                padding-right: 2em;
                padding-left: 2em;

                @media screen and (max-width:1440px) {
                    max-width: 35%;
                }
                @media screen and (max-width:1200px) {
                    max-width: 40%;
                }
                @media screen and (max-width:992px) {
                    max-width: 50%;
                }
                @media screen and (max-width:822px) {
                    max-width: 60%;
                }
                @media screen and (max-width:767px) {
                    max-width: 90%;
                    right: 1em;
                    top: 2em;
                    bottom: 2em;
                    left: 1em;
                    padding-top: 5%;
                    padding-bottom: 5%;
                }
            }

            .col-md-2{
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                        flex: 0 0 100%;
                max-width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                
                button{
                    margin: 0 auto;
                    max-width: 40%;
                    @media screen and (max-width:767px) {
                       max-width: 70%;
                    }
                }
            }
            .col-md-4{
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                        flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                
                p{
                    margin-bottom: 0;

                    @media screen and (max-width:922px) {
                       margin-bottom: 0.5em;
                    }
                }
            }
            .col-md-5{
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                        flex: 0 0 100%;
                max-width: 100%; padding-bottom: 1em;
                @media screen and (max-width:922px) {
                    -ms-flex: 0 0 100%;
                    -webkit-box-flex: 0;
                            flex: 0 0 100%;
                    max-width: 100%; 
                }
            }
            .col-md-3{
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%; 
                padding-top: 1em;
                padding-bottom: 1em;
                @media screen and (max-width:922px) {
                    -ms-flex: 0 0 100%;
                    -webkit-box-flex: 0;
                            flex: 0 0 100%;
                    max-width: 100%; 
                }
            }
            .col-md-8{
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                        flex: 0 0 100%;
                max-width: 100%;

            }
        }

        strong{
            font-size:30px;
            color:$cor2;
        }

        p{
            font-size:17px;
            color:$cor1;
        }

        #form-localidade{
            width:100%;
            float:left;
            padding-top:30px;

            @media(max-width:767px){
                padding-top:10px;
            }

            select{
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: url(../img/ico-seta-appearance.gif) no-repeat #eeeeee;
                background-position:right;
                width: 100%;
                height:37px;
                border:1px solid #ddd;
                float:left;

                @media(max-width:767px){
                    margin-bottom:10px;    
                }
            }

            button{
                background-color:$cor1;
                width:100%;
                float:left;
                -webkit-transition:all 0.4s;
                -o-transition:all 0.4s;
                transition:all 0.4s;
                border:0px;
                font-weight:bold;
                text-transform:uppercase;
                
                @media(max-width:767px){
                    font-size:20px;
                }
            }
        }
    }

    .col-cor-2{
        background-color: $cor1;
        
        @media screen and (min-width:1366px) {
            position: relative;
            overflow: hidden;
            &:before{
                content: ' ';
                position: absolute;
                top: 0;
                left: 0px;
                bottom: 0;
                border-top: 90.75px solid white;
                border-right: 40px solid transparent;
                border-bottom: 25px solid green;
                border-left: 0px solid blue;
            }
        }
      
        ul{
            li{
                list-style-type: none;
                a{

                }
            }
        }

        .col-busca{
            input{
                background: transparent;
                border: none;
                color: white;
            }

            a{
                border: none;
            }
        }
    }
}

/****** LOGIN MODAL ******/
.sombra-modal{
    -webkit-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.5);
    box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.5);
}
.loginmodal-container-header{
    padding: 15px 30px !important;
}
.loginmodal-container {
    padding: 30px;
   
    width: 100% !important;
    background-color: white;
    margin: 0 auto;
    border-radius: 2px;
   
    overflow: hidden;
    font-family: 'effraregular';
  }
  
  .loginmodal-container h1 {
    text-align: center;
    font-size: 1.8em;
    font-family: 'effraregular';
    color: $cor1;
    font-weight: bold;
    line-height: 60px;
  }
  
  .loginmodal-container input[type=submit] {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .loginmodal-container input[type=email], input[type=password] {
    height: 44px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 5px;
  }
  
  .loginmodal-container input[type=text]:hover, input[type=password]:hover {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  }
  
  .loginmodal {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    padding: 0 8px;
  /* border-radius: 3px; */
  /* -webkit-user-select: none;
    user-select: none; */
  }
  
  .loginmodal-submit {
    /* border: 1px solid #3079ed; */
    border: 0px;
    color: #fff;
    text-shadow: 0 1px rgba(0,0,0,0.1); 
    background-color: $cor1;
    padding: 17px 0px;
    font-family: 'effraregular';
    font-size: 14px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */

    &:hover{
        background-color: $cor2;
    }
  }
  
  .loginmodal-submit:hover {
    /* border: 1px solid #2f5bb7; */
    border: 0px;
    text-shadow: 0 1px rgba(0,0,0,0.3);
    background-color: #357ae8;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
  }
  
  .loginmodal-container a {
    text-decoration: none;
    color: #666;
    font-weight: 400;
    text-align: center;
    display: inline-block;
    opacity: 0.8;
    -webkit-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
    transition: all ease 0.4s;

    &:hover{
        opacity: 1;
        color: #0073A5;
    }
  } 
  
  .login-help{
    width: 100%;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  #modal-whatsapp{
    a{
        color: white;
        text-decoration: none;
    }

    p{
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    .chatzap{
      
        padding-right: 5%;
        padding-left: 5%;
    }

    .bloco-cinza{
        background: #f2f2f2;
        padding: 0.5rem 1rem;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        border-radius: 5px 5px 5px 0;
        max-width: 80%;
       
        margin-bottom: 20px;
        position: relative;

        p{
            font-size: 1rem;
        }

        &:after{
            position: absolute;
            content: ' ';
            left: 0;
            bottom: -10px;
            height: 1px;
            width: 1px;
          
            background: transparent;
            border: 5px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid #f2f2f2;
            border-top: 5px solid #f2f2f2;
            -webkit-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;          
        }
    }

    .bloco-whatsapp{
        background: #25d366; 
        color: white;
        padding: 0.5rem 1rem;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        border-radius: 5px 5px 0 5px;
        max-width: 80%;
      
        margin-bottom: 20px;
        margin-right: 0;
        margin-left: auto;
        position: relative;
        
        &:after{
            position: absolute;
            content: ' ';
            right: 0;
            bottom: -10px;
            height: 1px;
            width: 1px;
            background: transparent;
            border: 5px solid transparent;
            border-right: 10px solid #25d366;
            border-left: 10px solid transparent;
            border-top: 5px solid #25d366;
            -webkit-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;          
        }

        &:hover{
            background: darken($color: #25d366, $amount: 10);
            &:after{
                border-right: 10px solid darken($color: #25d366, $amount: 10);
                border-top: 5px solid darken($color: #25d366, $amount: 10);
            }
        }
    }
  }
